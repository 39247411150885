// @flow

import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../../components/IntroTeaser'
import Services from '../../components/Services'
import ServicesNav from '../../components/ServicePages/ServicesNav'
import SEO from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import CaseTeasers from '../../components/CaseStudy/CaseTeasers'
import Clients from '../../components/Clients'

import messages from '../../components/ServicePages/ServicePages.lang'
import indexMessages from '../../components/HomePage/HomePage.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import pageTitle from '../../messages/pageTitles.lang'
import baseStyles from '../../components/ServicePages/ServicePages.scss'
import { FormattedMessageSanitized } from '../../components/FormattedMessageSanitized'

/* eslint-disable max-len */
const imgCopyrightHref =
  'https://www.freepik.com/free-vector/computer-technology-isometric-icon-server-room-digital-device-set-element-design-pc-laptop_4103157.htm#query=system&position=18&from_view=search'
/* eslint-enable max-len */

const IntroImage = (
  <StaticImage
    src="../../images/services/subpages/web-application-and-platform-development.jpg"
    objectFit="contain"
    alt=""
    className="image"
  />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const ServicePage = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleDevelopment)}
        description={formatMessage(metaDescription.developmentDescription)}
        lang={locale}
      />
      <ServicesNav />
      <div className={styles.root}>
        <SubpageHeroTeaser title={formatMessage(messages.developmentpageTitle)} image={HeroTeaserImage} />
        <IntroTeaser
          title={formatMessage(messages.developmentintroTitle)}
          copy={
            <div className={styles.text}>
              <FormattedMessageSanitized {...messages.developmentIntroText} />
            </div>
          }
          image={IntroImage}
          imageConstrain
          imageCopyright={
            <a href={imgCopyrightHref} target="_blank" rel="noopener noreferrer">
              {formatMessage(messages.developmentImageCopyright)}
            </a>
          }
          fullWidth
          btn={formatMessage(messages.getConsultation)}
          btnLink="/contact/"
        />
      </div>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Clients title={formatMessage(indexMessages.sectionTitleReferences)} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers
          list={['dertouristik', 'plattenplaner', 'lhh', 'etravel', 'aldiana', 'weekend', 'bedfinder']}
          allButton
        />
      </ScrollAnimation>
      <Services
        services={[
          'consulting',
          'conceptStrategy',
          'design',
          'development',
          'systemIntegration',
          'systemEngineering',
          'appDevelopment',
          'hcmsIntegration'
        ]}
      />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicePage)
