import { defineMessages } from 'react-intl'

export default defineMessages({
  introTitle: 'Benefit from 15 years of experience in web development',
  introInfo: 'Web technology as a driver for your business - Why our customers are thrilled:',
  consulting: 'Expertise & experience in modern web technology',
  software: 'Extraordinary speed of development',
  engineering: 'Outstanding work quality',
  backend: 'Teamwork synergy',
  design: 'Full trust & transparency',
  btn: 'Our happy clients',
  sectionTitleReferences: 'Who has already benefited'
})
