// @flow

import React, { useMemo } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { Container } from '../../components/basic/Grid'
import { Link as ScrollLink } from 'react-scroll'
import Button from '../../components/basic/Button'
import ClientLogo from './Components/ClientLogo'

/* eslint-disable max-len */
const logoFesto = (
  <StaticImage src="../../images/clientlogos/logo-festo.png" alt="" objectFit="cover" className="image" />
)
const logoMefa = (
  <StaticImage src="../../images/clientlogos/logo-mitsubishi.png" objectFit="cover" alt="" className="image" />
)
const logo20 = (
  <StaticImage src="../../images/clientlogos/act-dertouristik.png" alt="" objectFit="cover" className="image" />
)

const logoAlltours = (
  <StaticImage src="../../images/clientlogos/logo-alltours.png" alt="" objectFit="contain" className="image" />
)
const logoARZ = <StaticImage src="../../images/clientlogos/logo-arz.png" alt="" objectFit="contain" className="image" />
const logoSMM = (
  <StaticImage
    src="../../images/clientlogos/logo-sparkasse-mark-und-mehrwert.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

/* eslint-enable max-len */

import messages from './Clients.lang'
import baseStyles from './Clients.scss'

type Props = {
  ...StyleProps,
  ancor?: boolean,
  defaultTitle?: boolean,
  title?: string
}

const Clients = ({ styles, defaultTitle, title, ancor }: Props) => {
  const { formatMessage } = useIntl()
  const buttonComponent = useMemo(
    () => (
      <Button styles={styles.btn} tag="div" icon="icon-arrow_03-right-medium">
        {formatMessage(messages.btnShowMore)}
      </Button>
    ),
    [styles]
  )

  return (
    <Container className={styles.root} fluid>
      {defaultTitle && (
        <div className={styles.defaultTitleSectionWrapper}>
          <div className={styles.defaultTitleWrapper}>
            <h2 className={styles.defaultTitle}>{formatMessage(messages.defaultTitle)}</h2>
            <p className={styles.defaultSubtitle}>{formatMessage(messages.defaultSubtitle)}</p>
          </div>
          <Link to="/references/">
            <Button styles={styles.defaultMoreBtn} tag="div" iconRight="icon-right" color="link">
              {formatMessage(messages.btnShowMore)}
            </Button>
          </Link>
        </div>
      )}
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.logoList}>
        <ClientLogo className={styles.logo} logo={logoMefa} />
        <ClientLogo className={styles.logo} logo={logoAlltours} />
        <ClientLogo className={styles.logo} logo={logoFesto} />
        <ClientLogo className={styles.logo} logo={logoARZ} />
        <ClientLogo className={styles.logo} logo={logo20} />
        {defaultTitle ? (
          <ClientLogo className={styles.logo} logo={logoSMM} />
        ) : (
          <div className={styles.btnWrap}>
            {ancor ? (
              <ScrollLink to="ClientOverview" smooth={true} offset={-70}>
                {buttonComponent}
              </ScrollLink>
            ) : (
              <Link to="/references/">{buttonComponent}</Link>
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Clients)
