// @flow

import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../../components/basic/Button'
import Carousel from '../../../components/basic/Carousel'
import { Container } from '../../../components/basic/Grid'
import messages from './CaseTeasers.lang'
import baseStyles from './CaseTeasers.scss'

import { projectsList } from '../helpers'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  allButton?: boolean,
  bottomTitle?: boolean,
  isOthers?: boolean,
  list: Array<string>
}

const CaseTeasers = ({ styles, list, allButton, bottomTitle, isOthers }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <h2 className={styles.title}>
          {isOthers ? (
            <FormattedMessageSanitized {...messages.othersTitle} />
          ) : (
            <FormattedMessageSanitized {...messages.title} />
          )}
        </h2>
        <Carousel
          styles={styles.slider}
          settings={{
            swipe: true,
            lazyLoad: false,
            infinite: false,
            dots: true,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1439,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  swipe: true
                }
              },
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  swipe: true
                }
              }
            ]
          }}
        >
          {list.map((item, i) => (
            <div className={styles.itemWrap} key={'casesItem' + i}>
              <div className={styles.item}>
                <div className={styles.imageWrap}>
                  <div className={styles.imageContainer}>{projectsList[item].marketingImage}</div>
                </div>
                <div className={styles.panel}>
                  <div className={styles.preTitle}>{formatMessage(projectsList[item].name)}</div>
                  <h2 className={styles.itemTitle}>
                    <FormattedMessageSanitized {...projectsList[item].title} />
                  </h2>
                  <p className={styles.description}>{formatMessage(projectsList[item].description)}</p>
                  <Link to={projectsList[item].localLink} className={styles.btnLink}>
                    <Button styles={styles.btn} tag="span" color="link">
                      {formatMessage(messages.btnCheck)}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
        {(allButton || bottomTitle) && (
          <div className={styles.footer}>
            {bottomTitle && (
              <h2 className={styles.title}>
                <FormattedMessageSanitized {...messages.moreProjectsTitle} />
              </h2>
            )}
            {allButton && (
              <Link to="/case-study/" className={styles.btnLinkAll}>
                <Button styles={styles.btnSection} tag="span" color="accent" iconRight="icon-arrow_03-right-medium">
                  {formatMessage(messages.btnCheckAll)}
                </Button>
              </Link>
            )}
          </div>
        )}
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseTeasers)
