import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  title: 'Selected success stories',
  othersTitle: 'Other case studies with proven success',
  moreProjectsTitle: 'More <b>successful projects</b>',
  btnCheck: 'Check it Out',
  btnCheckAll: 'All case studies'
})
